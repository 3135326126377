

.rbc-event-label{
    display:none;
}

.rbc-btn-group:last-child button:last-child{
    display:none
}

.myCustomHeight{
    width: max-content;
    overflow: scroll;
}
